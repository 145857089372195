html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.nav-link {
  padding: 0;
}

.nav-item a {
  padding: 0 .5rem;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

